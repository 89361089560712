define("explaincards-studio/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FdlIC/XV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"m-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu flex justify-between\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-3xl font-bold mb-6 text-orange-400\"],[12],[2,\"\\n      ExplainCards Studio\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-4 mr-4\"],[12],[2,\"\\n      \"],[1,[32,0,[\"session\",\"tokenData\",\"email\"]]],[2,\"-\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-4 mr-4\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[6,[37,0],null,[[\"route\"],[\"figurines\"]],[[\"default\"],[{\"statements\":[[2,\"Figurines\"]],\"parameters\":[]}]]],[2,\" |\\n        \"],[11,\"a\"],[4,[38,1],[\"click\",[32,0,[\"invalidateSession\"]]],null],[12],[2,\"Logout\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[6,[37,0],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Login\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"on\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "explaincards-studio/application/template.hbs"
    }
  });

  _exports.default = _default;
});