define("explaincards-studio/components/forms/input-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="md:flex md:items-center mb-5">
    <div class="md:w-1/5">
      <label class="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
        {{@label}}
      </label>
    </div>
    <div class="md:w-3/5">
      <Input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text" @value={{@value}} ...attributes />
    </div>
  </div>
  
  */
  {
    id: "f4OQDPlY",
    block: "{\"symbols\":[\"@label\",\"@value\",\"&attrs\"],\"statements\":[[10,\"div\"],[14,0,\"md:flex md:items-center mb-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md:w-1/5\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4\"],[14,\"for\",\"inline-full-name\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md:w-3/5\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500\"],[24,1,\"inline-full-name\"],[17,3],[24,4,\"text\"]],[[\"@value\"],[[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "explaincards-studio/components/forms/input-text.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});