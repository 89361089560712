define("explaincards-studio/figurines/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YRGpfpbw",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"text-2xl font-bold mb-6 text-orange-400\"],[12],[2,\"New figurine\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"figurines/edit\",[],[[\"@figurine\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "explaincards-studio/figurines/new/template.hbs"
    }
  });

  _exports.default = _default;
});