define("explaincards-studio/figurines/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R8ICV1is",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"flex mb-4\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"mr-6\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"text-blue-500 hover:text-blue-800\"]],[[\"@route\"],[\"figurines\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Figurines\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"mr-6\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"text-blue-500 hover:text-blue-800\"]],[[\"@route\"],[\"figurines.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      New figurine\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"mr-6\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"text-blue-500 hover:text-blue-800\"]],[[\"@route\"],[\"figurines.batch\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Upload figurines in batch\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"mr-6\"],[12],[2,\"|\"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"mr-6\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"text-blue-500 hover:text-blue-800\"]],[[\"@route\"],[\"figurines\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Words\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "explaincards-studio/figurines/template.hbs"
    }
  });

  _exports.default = _default;
});