define("explaincards-studio/components/forms/buttons/secondary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="shadow bg-white border-2 border-blue-600 hover:bg-blue-600 hover:text-white focus:shadow-outline focus:outline-none text-blue-600 font-bold py-2 px-4 rounded" type="button" {{on "click" @doAction}}>
    {{@label}}
  </button>
  
  */
  {
    id: "gOpWmFfp",
    block: "{\"symbols\":[\"@doAction\",\"@label\"],\"statements\":[[11,\"button\"],[24,0,\"shadow bg-white border-2 border-blue-600 hover:bg-blue-600 hover:text-white focus:shadow-outline focus:outline-none text-blue-600 font-bold py-2 px-4 rounded\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n  \"],[1,[32,2]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    meta: {
      moduleName: "explaincards-studio/components/forms/buttons/secondary.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});