define("explaincards-studio/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ytUL+xsg",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,0],[\"submit\",[32,0,[\"authenticate\"]]],null],[12],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"identification\"],[12],[2,\"Login\"],[13],[2,\"\\n  \"],[8,\"input\",[[24,1,\"identification\"],[24,\"placeholder\",\"Enter Login\"]],[[\"@value\"],[[32,0,[\"identification\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"password\"],[12],[2,\"Password\"],[13],[2,\"\\n  \"],[8,\"input\",[[24,1,\"password\"],[24,\"placeholder\",\"Enter Password\"]],[[\"@value\"],[[32,0,[\"password\"]]]],null],[2,\"\\n  \"],[10,\"button\"],[14,4,\"submit\"],[12],[2,\"Login\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[32,0,[\"errorMessage\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "explaincards-studio/login/template.hbs"
    }
  });

  _exports.default = _default;
});