define("explaincards-studio/components/odds/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nsCADez8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n    Pak de sterren met de even getallen en verdien punten. Pas op voor de oneven getallen!\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"S = links\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"F = rechts\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"spatie of E = springen\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,0,\"board\"],[4,[38,0],[[32,0,[\"createGame\"]]],null],[12],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "explaincards-studio/components/odds/template.hbs"
    }
  });

  _exports.default = _default;
});