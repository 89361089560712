define("explaincards-studio/components/figurines/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="w-40 bg-gray-200 rounded m-1 p-2 h-60">
    <div class="w-30 h-30">
      <!-- {{{ @figurine.svgFill }}} -->
      <img src={{@figurine.urls.fill-144x144}} alt="">
    </div>
    <div class="text-center text-sm">
      {{ @figurine.reference }}
    </div>
    <div class="text-center text-sm">
      {{ @figurine.designer }}
    </div>
    <div class="text-center text-sm">
      {{ @figurine.batch }}
    </div>
  </div>
  
  */
  {
    id: "f7yiTZcG",
    block: "{\"symbols\":[\"@figurine\"],\"statements\":[[10,\"div\"],[14,0,\"w-40 bg-gray-200 rounded m-1 p-2 h-60\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-30 h-30\"],[12],[2,\"\\n    \"],[3,\" {{{ @figurine.svgFill }}} \"],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[32,1,[\"urls\",\"fill-144x144\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center text-sm\"],[12],[2,\"\\n    \"],[1,[32,1,[\"reference\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center text-sm\"],[12],[2,\"\\n    \"],[1,[32,1,[\"designer\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center text-sm\"],[12],[2,\"\\n    \"],[1,[32,1,[\"batch\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "explaincards-studio/components/figurines/card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});