define("explaincards-studio/components/score-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYE9ktoG",
    "block": "{\"symbols\":[\"score\",\"@title\",\"@scores\"],\"statements\":[[10,\"h2\"],[12],[1,[32,2]],[13],[2,\"\\n\\n\"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,3,\"button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"showScore\"]],[32,1]],null]],null],[12],[1,[32,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "explaincards-studio/components/score-list/template.hbs"
    }
  });

  _exports.default = _default;
});