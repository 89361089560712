define("explaincards-studio/figurines/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "usDxIgG4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"text-2xl font-bold mb-6 text-orange-400\"],[12],[2,\"Figurines\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"figurines/cards\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "explaincards-studio/figurines/index/template.hbs"
    }
  });

  _exports.default = _default;
});